let playButton = document.getElementById('play-video');

if(playButton){
  playButton.addEventListener('click', function(){
    // create a new div element
    let playerId = "videoID";
    let videoId = "845223293";
    
    const videoLightbox = document.createElement("div");
    const videoContainer = document.createElement("div");
    const videoPlayer = document.createElement("div");
    const closeButton = document.createElement("button");
    

    videoLightbox.classList.add('video__lightbox');
    videoContainer.classList.add('video__lightbox-container');
    videoPlayer.id = playerId;
    closeButton.classList.add('video__close')
    closeButton.textContent = "zurück";

    closeButton.onclick = function(){
      videoLightbox.remove();
    }

    videoLightbox.onclick = function(){
      this.remove();
    }

    videoContainer.appendChild(videoPlayer)
    videoLightbox.appendChild(videoContainer);
    videoLightbox.appendChild(closeButton);
    document.body.appendChild(videoLightbox);

    
    let lightBoxPlayer = new Vimeo.Player(playerId, {
      id: videoId,
      width: '100%',
      responsive: true,
    });

    lightBoxPlayer.on('bufferend', lightBoxPlayer.play());

    
  });
}


/* Toggle Menu */
// function toggleSubMenu(){
let menuItems = document.querySelectorAll('li.has-submenu');

Array.prototype.forEach.call(menuItems, function(el, i){
  let nav = el.querySelector('nav');
  let button = el.querySelector('button');

  el.addEventListener("click", function(event){
    if(nav.classList.contains('visually-hidden')){
      button.setAttribute('aria-expanded', "true");
    }
    else{
      button.setAttribute('aria-expanded', "false");
    }
		nav.classList.toggle('visually-hidden');
	});
});




  // menuButton.addEventListener('click', function(){
  //   // add aria-expanded true / false to button --> accessibility
  //   let expanded = this.getAttribute('aria-expanded') === 'true' || false;
  //   this.setAttribute('aria-expanded', !expanded);
  //
  //   // open menulist
  //   menuList.classList.toggle('is-active');
  // });

// }



/* Toggle Mobile Menu */

const menuButton = document.getElementById('menubutton');
const menuList = document.getElementById('menubar');

menuButton.addEventListener('click', function(){
  // add aria-expanded true / false to button --> accessibility
  let expanded = this.getAttribute('aria-expanded') === 'true' || false;
  this.setAttribute('aria-expanded', !expanded);

  // open menulist
  menuList.classList.toggle('is-active');
});


